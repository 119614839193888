import ContentCard from './ContentCard';

export default function BoxWithTitle({ children, title, icon, sx, ...titleWithIconProps }) {
    return (
        <ContentCard
            title={title}
            small
            icon={icon}
            sx={{ boxShadow: 'none', border: '2px solid #000', ...sx }}
            {...titleWithIconProps}
        >
            {children ? children : null}
        </ContentCard>
    );
}
