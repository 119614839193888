import BoxWithTitle from '@/components/BoxWithTitle';
import SliderWithLabelAndDescription from '@/components/SliderWithLabelAndDescription';
import CustomTextField from '@components/CustomTextField';
import InfoBox from '@components/InfoBox';
import { colorForContrast, contrast, parseInputString } from '@helpers/functions';
import { Box, Button, Card, CardContent, CardHeader, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
    IconDeviceDesktop,
    IconDeviceMobile,
    IconHandClick,
    IconPalette,
    IconPhotoUp
} from '@tabler/icons-react';
import Color from 'color';
import { useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { AssistIcon } from '../AssistIcon';

export default function AppearanceForm({ dataValues, handleInputChange }) {
    const { t } = useTranslation();

    const timeoutRef = useRef();

    const debounce = (func, delay) => {
        return function (...args) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = null;
                func.apply(this, args);
            }, delay);
        };
    };

    const delayedFunction = debounce(async (name, value, validationFunc, validationProps) => {
        let hasError = false;
        if (validationFunc) {
            const validResp = await validationFunc(value, validationProps);
            hasError = !validResp;
            handleInputChange(name, value, hasError, true);
        }
    }, 500);

    function handleValueChanged({
        key,
        value,
        validator = undefined,
        validationProps = null,
        defaultError = false
    }) {
        handleInputChange(key, value, defaultError);

        delayedFunction(key, value, validator, validationProps);
    }

    return (
        <>
            <IconSizingCard dataValues={dataValues} handleValueChanged={handleValueChanged} />

            <Grid lg={6} xs={12}>
                <CustomColorsCard dataValues={dataValues} handleValueChanged={handleValueChanged} />
            </Grid>

            <Grid lg={6} xs={12}>
                <Box
                    height='100%'
                    display='flex'
                    justifyContent={{ xs: 'flex-start', xl: 'space-between' }}
                    gap={2}
                    flexDirection='column'
                >
                    <AdoptIconCard
                        dataValues={dataValues}
                        handleValueChanged={handleValueChanged}
                    />
                    <InfoBox text={t('customizeAppearanceText')}>
                        <Button
                            href='https://eye-able.com/einbettung-von-eye-able-direkt-in-die-seite/'
                            startIcon={<IconHandClick />}
                            variant='outlined'
                        >
                            {t('embeddingEyeableInPageBtnText')}
                        </Button>
                    </InfoBox>
                </Box>
            </Grid>
        </>
    );
}

function IconSizingCard({ dataValues, handleValueChanged }) {
    const { t } = useTranslation();

    const pixelTextFields = {
        desktop: {
            icon: IconDeviceDesktop,
            title: t('astCustomGDesktopSize'),
            inputFields: [
                {
                    key: 'iconSize',
                    title: t('titleIconSize'),
                    description: t('desIconSize'),
                    errorMsg: t('errorIconSize'),
                    defaultValue: '70px',
                    validProps: { min: 50, max: 70 }
                }
            ]
        },
        mobile: {
            icon: IconDeviceMobile,
            title: t('astCustomGMobileSize'),
            inputFields: [
                {
                    key: 'mobileIconSize',
                    title: t('titleMobileIconSize'),
                    description: t('desMobileIconSize'),
                    errorMsg: t('errorMobileIconSize'),
                    defaultValue: '50px',
                    validProps: { min: 40, max: 50 }
                }
            ]
        }
    };

    function validateIconSize(value, validProps) {
        return new Promise(resolve => {
            const [, numb] = parseInputString(value);
            const isValid = /^\d+$/.test(numb) && numb <= validProps.max && numb >= validProps.min;

            resolve(isValid);
        });
    }

    function getNumberValue(value) {
        if (typeof value === 'number') return value;

        const numbericValue = value.replace(/[^\d]/g, '');
        return Number(numbericValue);
    }

    return Object.keys(pixelTextFields).map(key => {
        return (
            <Grid key={key} lg={6} xs={12}>
                <BoxWithTitle
                    icon={pixelTextFields[key].icon}
                    title={pixelTextFields[key].title}
                    sx={{
                        height: '100%'
                    }}
                >
                    {pixelTextFields[key].inputFields.map(field => {
                        const numberValue = dataValues[field.key]?.value
                            ? getNumberValue(dataValues[field.key]?.value)
                            : field.validProps.min;
                        return (
                            <Grid
                                container
                                key={field.key}
                                justifyContent='space-between'
                                direction='column'
                                flexWrap='nowrap'
                                height='100%'
                                minHeight={{ xs: '226px', sm: '198px' }}
                                spacing={2.5}
                            >
                                <Grid
                                    alignItems='center'
                                    display='flex'
                                    justifyContent='center'
                                    xs={12}
                                >
                                    <Box className='assistIconContainer' sx={{ display: 'block' }}>
                                        <AssistIcon
                                            styleProps={{
                                                width: dataValues[field.key].value,
                                                height: dataValues[field.key].value,
                                                color: dataValues.customIconColor.value
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={12}>
                                    <SliderWithLabelAndDescription
                                        label={field.title}
                                        labelAddition='px'
                                        description={field.description}
                                        value={numberValue}
                                        min={field.validProps.min}
                                        max={field.validProps.max}
                                        defaultValue={numberValue}
                                        onChange={e =>
                                            handleValueChanged({
                                                key: field.key,
                                                value: e.target.value + 'px',
                                                validator: validateIconSize,
                                                validationProps: field.validProps
                                            })
                                        }
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
                </BoxWithTitle>
            </Grid>
        );
    });
}

function CustomColorsCard({ dataValues, handleValueChanged }) {
    const { t } = useTranslation();

    const colorInputFields = [
        {
            key: 'customIconColor',
            title: t('titleCustomIconColor'),
            description: '',
            errorMsg: t('errorCustomIconColor'),
            defaultValue: '#000000'
        },
        {
            key: 'customBackgroundColor',
            title: t('titleCustomBackgroundColor'),
            description: '',
            errorMsg: t('errorCustomBackgroundColor'),
            defaultValue: '#000000'
        }
    ];

    function validateColor(colorVal) {
        try {
            Color(colorVal);
            return new Promise(resolve => resolve(true));
        } catch (err) {
            return new Promise(resolve => resolve(false));
        }
    }

    return (
        <BoxWithTitle icon={IconPalette} title={t('astCustomGColors')}>
            <Grid container spacing={3}>
                {colorInputFields.map(field => {
                    let colorDataVal = dataValues[field.key].value ?? '#000';

                    let colorValue;
                    try {
                        colorValue = Color(colorDataVal).hex();
                    } catch (err) {
                        colorValue = Color('#000').hex();
                    }

                    return (
                        <Grid key={field.key} xl={6} xs={12}>
                            <Card sx={{ width: '100%', background: '#f1f1f1' }}>
                                <CardHeader
                                    subheader={
                                        <Typography mt={1}>
                                            {t('contrast')}:&nbsp;
                                            <Typography
                                                color={colorForContrast(
                                                    contrast(colorValue, '#ffffff')
                                                )}
                                                component='span'
                                            >
                                                {contrast(colorValue, '#ffffff')}
                                                :1
                                            </Typography>
                                        </Typography>
                                    }
                                    title={
                                        <Typography variant='h3' fontWeight={500}>
                                            {field.title}
                                        </Typography>
                                    }
                                />

                                <CardContent>
                                    <Box
                                        sx={{
                                            'width': '100%',
                                            '& .react-colorful': {
                                                width: 'auto',
                                                marginBottom: '20px'
                                            }
                                        }}
                                    >
                                        <HexColorPicker
                                            color={colorValue}
                                            onChange={color => {
                                                handleValueChanged({
                                                    key: field.key,
                                                    value: color
                                                });
                                            }}
                                        />
                                    </Box>

                                    <CustomTextField
                                        error={dataValues[field.key].error}
                                        fieldKey={field.key}
                                        handleChange={e => {
                                            handleValueChanged({
                                                key: field.key,
                                                value: e.target.value,
                                                validator: validateColor
                                            });
                                        }}
                                        value={dataValues[field.key].value}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}

                <Box
                    component='h3'
                    fontSize={1 + 'rem'}
                    fontWeight={500}
                    lineHeight={2}
                    sx={{
                        wordBreak: 'break-word',
                        letterSpacing: '0.2px',
                        my: 2,
                        color: '#4f4f4f'
                    }}
                >
                    {t('validColorValueDesc')}

                    <span className='badge-secondary'>red</span>

                    <span className='badge-secondary'>#FF0000</span>

                    <span className='badge-secondary'>rgb(255,0,0)</span>
                </Box>
            </Grid>
        </BoxWithTitle>
    );
}

function AdoptIconCard({ dataValues, handleValueChanged }) {
    const { t } = useTranslation();

    const iconUrlInputs = {
        externalLogoUrl: {
            title: t('astCustomFExtLogoUrlTitle'),
            description: t('astCustomFExtLogoUrlDesc'),
            errorMsg: t('astCustomFExtLogoUrlErrMsg')
        }
    };

    function isValidImageUrl(url) {
        if (url.trim() === '') return true;
        return new Promise(resolve => {
            const img = new Image();

            img.onload = function () {
                resolve(true);
            };

            img.onerror = function () {
                resolve(false);
            };
            img.src = url;
        });
    }

    return (
        <BoxWithTitle icon={IconPhotoUp} title={t('astCustomGAdoptIcon')}>
            {Object.entries(iconUrlInputs).map(([fieldKey, field]) => (
                <Grid container key={fieldKey} mt={2} spacing={3}>
                    <Grid
                        alignItems='center'
                        display='flex'
                        justifyContent='center'
                        style={{ height: '71px' }}
                        xs={12}
                    >
                        <Box sx={{ display: 'block' }}>
                            {dataValues[fieldKey].value !== '' && !dataValues[fieldKey].error ? (
                                <UrlAssistIcon iconUrl={dataValues[fieldKey].value} />
                            ) : (
                                <AssistIcon
                                    styleProps={{
                                        color: dataValues.customIconColor.value
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid xs={12}>
                        <CustomTextField
                            description={field.description}
                            error={dataValues[fieldKey].error}
                            errorMsg={field.errorMsg}
                            fieldKey={fieldKey}
                            handleChange={e =>
                                handleValueChanged({
                                    key: fieldKey,
                                    value: e.target.value.trim(),
                                    validator: isValidImageUrl,
                                    defaultError: Boolean(e.target.value !== '')
                                })
                            }
                            label={field.title}
                            placeholder='https://eye-able.com/en/uploads/myNewIcon.svg'
                            value={dataValues[fieldKey].value}
                        />
                    </Grid>
                </Grid>
            ))}
        </BoxWithTitle>
    );
}

function UrlAssistIcon({ iconUrl }) {
    return (
        <img
            alt='Assist Uploaded Icon'
            src={iconUrl}
            style={{
                height: '70px',
                width: '70px'
            }}
        />
    );
}
