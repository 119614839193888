import {
    currentAuditEntryAtom,
    leftDrawerOpenAtom,
    reportDemoModeAtom,
    userAtom
} from '@/global-store';
import LoadingSpinner from '@components/LoadingSpinner';
// import Breadcrumbs from './Breadcrumbs';
import { sidebarWidth } from '@helpers/constants';
import useWindowSize from '@hooks/useWindowSize';
import { Alert, AppBar, Box, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigation } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
//import UserTour from '@components/UserTour.jsx';
import DemoModeBanner from '@/components/DemoModeBanner';
import FeatureOfferBanner from '@/components/FeatureOfferBanner';
import PageTitle from '@/components/PageTitle';
import { useFetchAccessibilityData } from '@/http/queries';
import DomainSelect from '@/pages/report/reportComponents/DomainSelect';
import theme from '@assets/theme/index.jsx';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

// styles
const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
    backgroundColor: '#f1f1f1',
    width: '100%',
    maxWidth: `calc(100% - (${sidebarWidth}px + 32px))`,
    minHeight: 'calc(100vh - 68px)',
    flexGrow: 1,
    padding: '32px',
    marginRight: '32px',
    marginTop: '65px',
    borderRadius: theme.borders.borderRadius.xl,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,

    [theme.breakpoints.down('xxl')]: {
        marginRight: '16px',
        maxWidth: `calc(100% - (${sidebarWidth}px + 16px))`
    },

    [theme.breakpoints.down('xl')]: {
        padding: '26px',
        marginRight: 0,
        maxWidth: '100%'
    },

    [theme.breakpoints.down('sm')]: {
        padding: '0px'
    },

    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    }),

    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

function LastAuditTooOldBanner() {
    const { AccessibilityData } = useFetchAccessibilityData();
    const [currentAuditEntry] = useAtom(currentAuditEntryAtom);
    const { t } = useTranslation();

    if (!AccessibilityData?.history || !currentAuditEntry?.auditInterval) return null;

    const historyKeys = Object.keys(AccessibilityData.history);
    const lastAudit = historyKeys[historyKeys?.length - 1];

    if (!lastAudit) return null;

    const lastAuditMs = new Date(lastAudit).getTime();
    const now = new Date().getTime();
    const auditIntervalMs = 1000 * 60 * 60 * 24 * (currentAuditEntry.auditInterval + 2); // add two day buffer

    if (lastAuditMs + auditIntervalMs > now) return null;

    return (
        <Alert
            severity='error'
            sx={{
                'border': '4px solid black',
                'color': theme.palette.black.main,
                'alignItems': 'center',
                'display': 'flex',
                'flexWrap': 'nowrap',
                'width': '100%',
                'my': 3,
                'p': 2,
                '& .MuiAlert-message': {
                    flexShrink: 1,
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 700
                }
            }}
        >
            {t('lastAuditTooOld')}
        </Alert>
    );
}

function ReportLicenseExpiredBanner() {
    const { t } = useTranslation();

    console.log('ReportLicenseExpiredBanner');

    return (
        <>
            <Alert
                severity='error'
                sx={{
                    'border': '4px solid black',
                    'color': theme.palette.black.main,
                    'alignItems': 'center',
                    'display': 'flex',
                    'flexWrap': 'nowrap',
                    'width': '100%',
                    'my': 3,
                    'p': 2,
                    '& .MuiAlert-message': {
                        flexShrink: 1,
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 700
                    }
                }}
            >
                {t('reportLicenseExpired')}
            </Alert>

            <FeatureOfferBanner product='Eye-Able® Report' />
        </>
    );
}

function showPageTitle(path) {
    const hiddenPaths = ['/ally', '/contrast-check', '/admin/', '/assist/', '/audit', '/demo'];

    for (const hiddenPath of hiddenPaths) {
        if (path.startsWith(hiddenPath)) return false;
    }

    return true;
}

// ==============================|| MAIN LAYOUT ||============================== //
function MainLayout() {
    const theme = useTheme();
    const location = useLocation();

    const { t } = useTranslation();

    const path = location.pathname.endsWith('/')
        ? location.pathname.slice(0, -1)
        : location.pathname;

    const isReportArea = path.startsWith('/report');

    const { isError, isLoading } = useFetchAccessibilityData();

    const showDomainSelect =
        isReportArea && !isLoading && !path.startsWith('/report/accessibility-statement/generator');

    const showConformanceTargetSelect =
        isReportArea &&
        !isError &&
        !isLoading &&
        !path.startsWith('/report/accessibility-statement') &&
        !path.startsWith('/report/analysis');

    const [user] = useAtom(userAtom);
    const [reportDemoMode] = useAtom(reportDemoModeAtom);
    const [currentAuditEntry] = useAtom(currentAuditEntryAtom);

    const reportLicenseExpired =
        currentAuditEntry?.endDate && !user.isAdmin
            ? new Date(currentAuditEntry.endDate) < new Date()
            : false;

    /*const steps = [
    {
      content: t('dashboardGuideHomeWelcome'),
      title: t('dashboardGuideTitle'),
      placement: 'center',
      target: 'body',
      disableBeacon: true
    },
    {
      target: '#header-right-section',
      content: t('dashboardGuideHomeStep1'),
      disableBeacon: true
    },
    {
      target: '#collapse-assist',
      content: t('dashboardGuideHomeStep3'),
      disableBeacon: true
    },
    {
      target: '#route-audit',
      content: t('dashboardGuideHomeStep4'),
      disableBeacon: true
    },
    {
      target: '#list-subheader-report',
      content: t('dashboardGuideHomeStep5'),
      disableBeacon: true
    },
    {
      target: '#collapse-report-analysis',
      content: t('dashboardGuideHomeStep6'),
      disableBeacon: true
    },
    {
      target: '#list-subheader-tools',
      content: t('dashboardGuideHomeStep7'),
      disableBeacon: true
    },
    {
      target: '#support-popup',
      content: t('dashboardGuideHomeStep8'),
      disableBeacon: true
    }
  ];*/

    const windowSize = useWindowSize();

    const navigation = useNavigation();

    // Handle left drawer
    const [leftDrawerOpen, setLeftDrawerOpen] = useAtom(leftDrawerOpenAtom);

    const handleLeftDrawerToggle = () => {
        setLeftDrawerOpen(!leftDrawerOpen);
    };

    const appNavbar = useRef(null);

    useEffect(() => {
        function handleTransparentNavbar() {
            //const appNavbar = document.getElementById('AppNavbar');
            if (!appNavbar?.current) return;
            if (window.scrollY < 5) {
                appNavbar.current.style.background = 'none';
                appNavbar.current.style['backdrop-filter'] = 'none';
            } else {
                appNavbar.current.style.background = 'rgba(0,0,0, 0.85)';
                appNavbar.current.style['backdrop-filter'] = 'blur(5px)';
            }
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener('scroll', handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        return () => window.removeEventListener('scroll', handleTransparentNavbar);
    }, []);

    useEffect(() => {
        function sidenavEventCallback() {
            const xlAndUp = window.innerWidth >= theme.breakpoints.values.xl;
            setLeftDrawerOpen(xlAndUp);
        }
        sidenavEventCallback();
    }, [location.pathname, windowSize.width, setLeftDrawerOpen, theme.breakpoints.values.xl]);

    return (
        <Box sx={{ display: 'flex' }}>
            {/* header */}
            <AppBar
                elevation={0}
                enableColorOnDark
                id='AppNavbar'
                position='fixed'
                ref={appNavbar}
                sx={{
                    background: 'transparent',
                    color: theme.palette.primary.white,
                    transition: leftDrawerOpen ? theme.transitions.create('width') : 'none',
                    p: 0
                }}
            >
                <Toolbar
                    sx={{
                        'minHeight': '64px',
                        'background': 'transparent',
                        'pl': {
                            xs: 2 + '!important',
                            md: 3 + '!important'
                        },
                        'pr': {
                            xs: 2 + '!important',
                            md: 3 + '!important',
                            lg: '16px !important',
                            xl: '32px !important'
                        },

                        '@media (max-width: 270px)': {
                            px: 1.5 + '!important'
                        }
                    }}
                >
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpen} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main id='main-content' open={leftDrawerOpen} theme={theme}>
                <Grid
                    container
                    spacing={3}
                    direction='column'
                    sx={({ breakpoints }) => ({
                        margin: '0 auto',
                        maxWidth: breakpoints.values.xxl + 'px',
                        height: '100%'
                    })}
                >
                    {/* <Breadcrumbs /> */}

                    {navigation.state === 'loading' ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            {isReportArea && isError ? (
                                <Grid
                                    alignItems='center'
                                    container
                                    gap={5}
                                    justifyContent='center'
                                    p={4}
                                    sx={{
                                        background: 'white',
                                        borderRadius: 3
                                    }}
                                >
                                    <Grid xs={12}>
                                        <DomainSelect />
                                    </Grid>

                                    <Grid xs={12}>
                                        <h2>{t('noReportAvailable')}</h2>
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    {path && showPageTitle(path) && (
                                        <Grid xs={12}>
                                            <PageTitle
                                                title={document?.title?.replace(
                                                    ' | Eye-Able® Dashboard',
                                                    ''
                                                )}
                                                showDomainSelect={showDomainSelect}
                                                showConformanceTarget={showConformanceTargetSelect}
                                            />
                                        </Grid>
                                    )}

                                    {isReportArea ? (
                                        reportDemoMode ? (
                                            <DemoModeBanner product='Report' />
                                        ) : reportLicenseExpired ? (
                                            <ReportLicenseExpiredBanner />
                                        ) : (
                                            <LastAuditTooOldBanner />
                                        )
                                    ) : null}

                                    {isReportArea && isLoading ? (
                                        <LoadingSpinner />
                                    ) : isReportArea &&
                                      reportLicenseExpired &&
                                      path !== '/report/overview' ? null : (
                                        <Outlet />
                                    )}

                                    {/*<UserTour steps={steps} isIntroTour />*/}

                                    <Footer />
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </Main>
        </Box>
    );
}

export default MainLayout;
