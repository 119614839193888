import { demoModeAtom } from '@/global-store.jsx';
import useWindowSize from '@hooks/useWindowSize.jsx';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useAtom } from 'jotai';
import NavBadge from '../NavBadge';

// ==============================|| MAIN LOGO ||============================== //
function LogoSection({ dark, badgeHiddenOnMobile = false }) {
    const [demoMode] = useAtom(demoModeAtom);
    const windowsSize = useWindowSize().width;
    const isMobile = windowsSize < '370';

    const showBadge = demoMode && (badgeHiddenOnMobile ? !isMobile : true);

    return (
        <Grid
            alignItems='center'
            container
            sx={{
                'whiteSpace': 'nowrap',
                'pr': 2,
                '@media (max-width: 320px)': {
                    pr: 0.5
                }
            }}
        >
            <Box
                aria-hidden='true'
                component='img'
                src={
                    dark
                        ? 'https://eye-able-storage.b-cdn.net/dashboard-assets/icons/Icon_Dashboard_eye-able_black.svg'
                        : 'https://eye-able-storage.b-cdn.net/dashboard-assets/icons/Icon_Dashboard_eye-able_white.svg'
                }
                sx={{
                    'ml': { xs: -0.5, md: -1 },
                    'width': '3.9rem',
                    'bgcolor': 'transparent',
                    '@media (max-width: 1320px)': {
                        width: '3.25rem'
                    },
                    '@media (max-width: 290px)': {
                        width: '2.6rem'
                    }
                }}
            />

            <Box
                sx={{
                    'whiteSpace': 'nowrap',
                    'color': dark ? '#000' : '#fff',
                    'position': 'relative',
                    'fontSize': '1.15rem',
                    'mr': 3,
                    '@media (max-width: 290px)': {
                        fontSize: '0.9rem'
                    }
                }}
            >
                <b>Eye-Able</b>

                <span style={{ position: 'absolute', right: '-12px', top: '-4px' }}>&#174;</span>
            </Box>

            {showBadge ? <NavBadge text='demo' /> : null}
        </Grid>
    );
}

export default LogoSection;
