const dataTableFilters = [
    'issuesColumnFilters',
    'topIssuesColumnFilters',
    'orgColumnFilters',
    'issuesDetailColumnFilters',
    'brokenLinksColumnFilters',
    'userPdfsColumnFilters',
    'foundPdfsColumnFilters',
    'pagesColumnFilters',
    'pageDetailColumnFilters',
    'spellCheckColumnFilters',
    'spellCheckSitesColumnFilters',
    'spellCheckDictColumnFilters',
    'spellCheckSiteDetailColumnFilters',
    'lightHouseColumnFilters',
    'assistColumnFilters',
    'auditColumnFilters'
];

const dataTableSortings = [
    'issuesColumnSorting',
    'topIssuesColumnSorting',
    'orgColumnSorting',
    'issuesDetailColumnSorting',
    'brokenLinksColumnSorting',
    'userPdfsColumnSorting',
    'foundPdfsColumnSorting',
    'pagesColumnSorting',
    'pageDetailColumnSorting',
    'spellCheckColumnSorting',
    'spellCheckSitesColumnSorting',
    'spellCheckDictColumnSorting',
    'spellCheckSiteDetailColumnSorting',
    'lightHouseColumnSorting',
    'assistColumnSorting',
    'auditColumnSorting'
];

const exeptions = [
    'eyeAbleVariables',
    'cookieBannerAccepted',
    'versionOfLastNotificationSeen',
    'notificationLastViewDate',
    'defaultMyGoalsBoardFilters',
    'i18nextLng',
    'userToursSeen',
    ...dataTableFilters,
    ...dataTableSortings
];

function clearStorageWithExeptions() {
    for (let key in localStorage) {
        if (!exeptions.includes(key)) {
            localStorage.removeItem(key);
        }
    }

    sessionStorage.clear();

    /* for (let key in sessionStorage) {
        if (!exeptions.includes(key)) {
            localStorage.removeItem(key);
        }
    } */
}

export default clearStorageWithExeptions;
