import LoadingSpinner from '@components/LoadingSpinner';
import TitleWithIcon from '@components/TitleWithIcon';
import { Box, Card } from '@mui/material';
import PropTypes from 'prop-types';

function ContentCard({
    cardHeight = 'auto',
    sx = {},
    loading = false,
    children,
    noContentPadding = false,
    small = false,
    endSlot = null,
    wrapHeader = false,
    contentDescription = null,
    id = null,
    hideTitle = false,
    ...titleWithIconProps
}) {
    const base_padding = small ? 2 : 3;

    const padding = {
        xs: base_padding,
        md: base_padding + 1,
        lg: base_padding + 1.25,
        xl: base_padding + 1.5,
        xxl: base_padding + 1.75
    };

    return (
        <Card
            className='ContentCard'
            id={id}
            sx={{
                height: cardHeight,
                p: 0,
                m: 0,
                borderRadius: 3,
                overflow: 'hidden', // enforce border-radius
                ...sx
            }}
        >
            {!hideTitle && (
                <Box
                    className='ContentCardFlexBox'
                    sx={{
                        display: 'flex',
                        alignItems: { xs: 'stretch', lg: 'flex-start' },
                        gap: 2,
                        flexDirection: { xs: 'column', lg: 'row' },
                        flexWrap: wrapHeader ? 'wrap' : 'nowrap',
                        justifyContent: 'flex-end',
                        pt: padding,
                        px: padding,
                        pb: children ? 0 : padding
                    }}
                >
                    <Box sx={{ flexShrink: 1, flexGrow: 1, minWidth: '200px', pr: 1 }}>
                        <TitleWithIcon large={!small} small={small} {...titleWithIconProps} />
                    </Box>

                    {endSlot}
                </Box>
            )}

            {contentDescription ? (
                <Box
                    sx={{
                        pt: base_padding,
                        px: padding,
                        pb: 0,
                        whiteSpace: 'pre-line'
                    }}
                >
                    {contentDescription}
                </Box>
            ) : null}

            {loading ? (
                <LoadingSpinner />
            ) : children ? (
                <Box
                    height='100%'
                    mt={small ? 2 : 4}
                    pb={noContentPadding ? 0 : padding}
                    pt={0}
                    px={noContentPadding ? 0 : padding}
                >
                    {children}
                </Box>
            ) : null}
        </Card>
    );
}

ContentCard.propTypes = {
    cardHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loading: PropTypes.bool,
    noContentPadding: PropTypes.bool,
    small: PropTypes.bool,
    hideTitle: PropTypes.bool,
    titleWithIconProps: PropTypes.object
};

export default ContentCard;
