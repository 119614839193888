import BoxWithTitle from '@/components/BoxWithTitle';
import SliderWithLabelAndDescription from '@/components/SliderWithLabelAndDescription';
import HtmlTooltip from '@components/HtmlTooltip';
import InputHelperText from '@components/InputHelperText';
import {
    Box,
    FormControl,
    FormControlLabel,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Switch,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { IconInfoCircle } from '@tabler/icons-react';
import { MuiChipsInput } from 'mui-chips-input';
import { useTranslation } from 'react-i18next';

export default function AdvancedForm({ dataValues, handleInputChange }) {
    return (
        <>
            {/* magnification sliders */}
            <Grid xs={12}>
                <Magnification dataValues={dataValues} handleInputChange={handleInputChange} />
            </Grid>

            {/* varios setting / switch boxes / language dropdown  */}
            <Grid xs={12}>
                <VariousSettings dataValues={dataValues} handleInputChange={handleInputChange} />
            </Grid>

            {/* exclude from function fields */}
            <Grid xs={12}>
                <ExcludeElements dataValues={dataValues} handleInputChange={handleInputChange} />
            </Grid>
        </>
    );
}

function Magnification({ dataValues, handleInputChange }) {
    const { t } = useTranslation();

    const sliderFields = {
        maxMagnification: {
            title: t('titleMaxDesktopMagnification'),
            description: 'Maximum Magnification level',
            errorMsg: '',
            fieldProps: {
                min: 3,
                max: 20,
                step: 1
            }
        },
        maxMobileMagnification: {
            title: t('titleMaxMobileMagnification'),
            description: 'Maximum Mobile Magnification level',
            errorMsg: '',
            fieldProps: {
                min: 2,
                max: 5,
                step: 1
            }
        }
    };

    return (
        <BoxWithTitle title={t('astCustomGMaxMagnifiTitle')}>
            <Box
                component='h3'
                fontSize={1 + 'rem'}
                fontWeight={500}
                lineHeight={1.5}
                sx={{
                    wordBreak: 'break-word',
                    letterSpacing: '0.2px',
                    my: 2,
                    color: '#4f4f4f'
                }}
            >
                {t('astCustomGMaxMagnifiSubitle')}
            </Box>
            <Box
                sx={{
                    p: 1.25,
                    display: 'flex',
                    gap: { xs: 2.5, lg: 7, xxl: 12 },
                    flexDirection: { xs: 'column', lg: 'row' },
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {Object.entries(sliderFields).map(([fieldKey, field]) => {
                    return (
                        <SliderWithLabelAndDescription
                            key={fieldKey}
                            label={field.title}
                            value={Number(dataValues[fieldKey].value)}
                            min={field.fieldProps.min}
                            max={field.fieldProps.max}
                            defaultValue={Number(dataValues[fieldKey]) || field.fieldProps.min}
                            onChange={e => handleInputChange(fieldKey, e.target.value)}
                        />
                    );
                })}
            </Box>
        </BoxWithTitle>
    );
}

function VariousSettings({ dataValues, handleInputChange }) {
    const { t } = useTranslation();

    const selectFields = {
        userLang: {
            title: t('userLangTitle'),
            description: t('astCustomFUserLangDesc'),
            tooltipText: t('astCustomFUserLangToolitip'),
            errorMsg: '',
            options: [
                { value: 'af', label: 'Afrikaans' },
                { value: 'ar', label: '(اللغة) العربي' },
                { value: 'id', label: 'Bahasa Indonesia' },
                { value: 'ms', label: 'Bahasa Melayu' },
                { value: 'cs', label: 'čeština' },
                { value: 'zh', label: '中文' },
                { value: 'da', label: 'Dansk' },
                { value: 'de', label: 'Deutsch' },
                { value: 'et', label: 'Eesti keel' },
                { value: 'en', label: 'English' },
                { value: 'el', label: 'Ελληνικά' },
                { value: 'es', label: 'Español' },
                { value: 'fr', label: 'Français' },
                { value: 'hr', label: 'Hrvatski' },
                { value: 'hi', label: 'हिन्दी' },
                { value: 'xh', label: 'isiXhosa' },
                { value: 'zu', label: 'isiZulu' },
                { value: 'it', label: 'Italiano' },
                { value: 'ja', label: '日本語' },
                { value: 'ko', label: '한국의' },
                { value: 'lv', label: 'Latviski' },
                { value: 'lt', label: 'Lietuvių' },
                { value: 'no', label: 'Norsk' },
                { value: 'nl', label: 'Nederlands' },
                { value: 'uk', label: 'український' },
                { value: 'ro', label: 'Română' },
                { value: 'pl', label: 'Polski' },
                { value: 'pt', label: 'Português' },
                { value: 'ru', label: 'Pу́сский' },
                { value: 'fil', label: 'Wikang Filipino' },
                { value: 'sr', label: 'Српски' },
                { value: 'sk', label: 'Slovenský' },
                { value: 'sl', label: 'Slovenščina' },
                { value: 'st', label: 'Sesotho' },
                { value: 'fi', label: 'Suomi' },
                { value: 'sv', label: 'Svenska' },
                { value: 'tr', label: 'Türkçe' },
                { value: 'ts', label: 'Xitsonga' }
            ]
        }
    };

    const swtichFields = {
        disableAllShortcuts: {
            title: t('disableAllShortcutsTitle'),
            description: t('astCustomDisableAllShortcutsDescription'),
            tooltipText: t('astCustomDisableAllShortcutsDescription')
        },
        hideIcon: {
            title: t('hideIconTitle'),
            description: t('astCustomHideIconDescription'),
            tooltipText: t('astCustomHideIconTooltip'),
            toolTipLink: 'https://eye-able.com/einbettung-von-eye-able-direkt-in-die-seite/'
        },
        shareLocalStorage: {
            title: t('shareLocalStorageTitle'),
            description: t('astCustomShareLocalStorageDescription'),
            tooltipText: t('astCustomShareLocalStorageTooltip')
        },
        forceInlineColor: {
            title: t('forceInlineColorTitle'),
            description: t('astCustomForceInlineColorDescription'),
            tooltipText: t('astCustomForceInlineColorTooltip')
        }
    };

    return (
        <BoxWithTitle title={t('astCustomVariousSettingTitle')}>
            <Box
                component='h3'
                fontSize={1 + 'rem'}
                fontWeight={500}
                lineHeight={1.5}
                sx={{
                    wordBreak: 'break-word',
                    letterSpacing: '0.2px',
                    my: 2,
                    color: '#4f4f4f'
                }}
            >
                {t('astCustomVariousSettingSubtitle')}
            </Box>

            <Grid container spacing={4}>
                {Object.entries(swtichFields).map(([fieldKey, field]) => {
                    return (
                        <Grid key={fieldKey} lg={6} xs={12}>
                            <FormControl fullWidth>
                                <HtmlTooltip
                                    placement='top'
                                    tabIndex={-1}
                                    title={
                                        <>
                                            <Typography variant='subtitle2'>
                                                {field.title}
                                            </Typography>

                                            <Typography variant='body2'>
                                                {field.tooltipText}
                                            </Typography>

                                            {field.toolTipLink ? (
                                                <Link
                                                    href={field.toolTipLink}
                                                    sx={{ fontWeight: 600 }}
                                                    target='_blank'
                                                    underline='hover'
                                                >
                                                    {field.toolTipLink}
                                                </Link>
                                            ) : null}
                                        </>
                                    }
                                >
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={dataValues[fieldKey].value}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    onChange={e =>
                                                        handleInputChange(
                                                            fieldKey,
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            'color': '#787878',
                                                            'transition': 'color 0.2s', // Optional: Add a smooth transition effect
                                                            '&:hover': {
                                                                color: '#000'
                                                            }
                                                        }}
                                                    >
                                                        <IconInfoCircle size={24} />
                                                    </Box>

                                                    <Typography variant='body1'>
                                                        {field.title}
                                                    </Typography>
                                                </Box>
                                            }
                                            // label={field.title}
                                            labelPlacement='start'
                                        />

                                        <InputHelperText text={field.description} />
                                    </Box>
                                </HtmlTooltip>
                            </FormControl>
                        </Grid>
                    );
                })}

                {Object.entries(selectFields).map(([fieldKey, field]) => (
                    <Grid key={fieldKey} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id={fieldKey}>{field.title}</InputLabel>

                            <Select
                                label={field.title}
                                labelId={fieldKey}
                                onChange={e => handleInputChange(fieldKey, e.target.value)}
                                value={dataValues[fieldKey].value}
                            >
                                <MenuItem value='auto'>{t('autoDetect')}</MenuItem>

                                {field.options.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {`${option.label} (${option.value})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <InputHelperText text={field.tooltipText} />
                    </Grid>
                ))}
            </Grid>
        </BoxWithTitle>
    );
}

function ExcludeElements({ dataValues, handleInputChange }) {
    const { t } = useTranslation();

    const chipInputFields = {
        blacklistContrast: {
            title: t('titleBlacklistContrast'),
            description: t('descBlacklistContrast'),
            errorMsg: ''
        },
        blacklistDarkmode: {
            title: t('titleBlacklistDarkmode'),
            description: t('descBlacklistDarkmode'),
            errorMsg: ''
        },
        blacklistFontsize: {
            title: t('titleBlacklistFontsize'),
            description: t('descBlacklistFontsize'),
            errorMsg: ''
        },
        forceWhiteBackground: {
            title: t('titleForceWhiteBackground'),
            description: t('descForceWhiteBackground'),
            errorMsg: ''
        }
    };

    function isValidCssSelector(selector) {
        try {
            document.querySelector(selector);
            return true;
        } catch (error) {
            return false;
        }
    }

    const handleChipsChange = (fieldKey, newChips) => {
        const validChips = newChips.filter(chip => isValidCssSelector(chip));
        handleInputChange(fieldKey, [...new Set(validChips)].join());
    };

    const getChipsListFromStr = strValue => {
        let chips = [];
        if (strValue.trim() !== '') chips = strValue.split(',');
        return chips;
    };

    return (
        <BoxWithTitle title={t('astCustomExcluseFunctionsTitle')}>
            <Box
                component='h3'
                fontSize={1 + 'rem'}
                fontWeight={500}
                lineHeight={1.5}
                sx={{
                    wordBreak: 'break-word',
                    letterSpacing: '0.2px',
                    my: 2,
                    color: '#4f4f4f'
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: t('astCustomExcluseFunctionsSubtitle') }} />
            </Box>

            <Grid container spacing={4}>
                {Object.entries(chipInputFields).map(([fieldKey, field]) => {
                    return (
                        <Grid key={fieldKey} lg={6} xs={12}>
                            <InputLabel htmlFor={fieldKey} sx={{ fontSize: '.9rem' }}>
                                {field.title}
                            </InputLabel>

                            <MuiChipsInput
                                InputLabelProps={{ style: { fontSize: '1.1rem' } }}
                                error={dataValues[fieldKey].error}
                                fullWidth
                                helperText={dataValues[fieldKey].error ? field.errorMsg : ''}
                                name={fieldKey}
                                onChange={newChips => handleChipsChange(fieldKey, newChips)}
                                placeholder={t('chipInputPlaceHolder')}
                                value={getChipsListFromStr(dataValues[fieldKey].value)}
                                variant='filled'
                            />

                            <InputHelperText text={field.description} />
                        </Grid>
                    );
                })}
            </Grid>

            <Box
                component='h3'
                fontSize={1 + 'rem'}
                fontWeight={500}
                lineHeight={1.5}
                sx={{
                    wordBreak: 'break-word',
                    letterSpacing: '0.2px',
                    my: 2,
                    color: '#4f4f4f'
                }}
            >
                {t('cssSelectorsInfoLink')}

                <Link
                    href='https://www.w3schools.com/cssref/css_selectors.php'
                    sx={{ fontWeight: 400, color: '#555', marginLeft: '5px', fontStyle: 'italic' }}
                    target='_blank'
                    underline='hover'
                >
                    https://www.w3schools.com/cssref/css_selectors.php
                </Link>
            </Box>
        </BoxWithTitle>
    );
}
