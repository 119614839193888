import ConformanceTargetSelect from '@/pages/report/reportComponents/ConformanceTargetSelect';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DomainSelect from '../pages/report/reportComponents/DomainSelect';

function PageTitle({
    title = 'No Title',
    showDomainSelect = false,
    showConformanceTarget = false
}) {
    return (
        <Grid alignItems='center' container gap={2} justifyContent='space-between'>
            <Box component='h1' fontSize='1.65rem' fontWeight={600}>
                {title}
            </Box>
            <Box display='flex' gap={1.35} alignItems='center' flexWrap='wrap' justifyContent='center'>
                {showDomainSelect && <DomainSelect />}
                {showConformanceTarget && <ConformanceTargetSelect small />}
            </Box>
        </Grid>
    );
}

export default PageTitle;
