import { Box, Slider } from '@mui/material';

export default function SliderWithLabelAndDescription({
    label,
    description,
    labelAddition,
    value,
    min,
    max,
    defaultValue,
    step = 1,
    onChange,
    width = '100%',
    inputProps = {},
    ...sliderProps
}) {
    function getValueWithLabelAddition(value) {
        return labelAddition ? value + labelAddition : value;
    }

    return (
        <Box width={width}>
            <Box
                id={label + defaultValue}
                sx={{
                    color: 'text.primary',
                    fontWeight: 500,
                    mb: 0.5
                }}
            >
                {label}: {getValueWithLabelAddition(value)}
            </Box>
            <Box px={0.4}>
                <Slider
                    getAriaValueText={getValueWithLabelAddition}
                    valueLabelFormat={getValueWithLabelAddition}
                    {...sliderProps}
                    {...inputProps}
                    aria-labelledby={label + defaultValue}
                    onChange={onChange}
                    min={min}
                    max={max}
                    defaultValue={defaultValue}
                    step={step}
                    shiftStep={step}
                    value={value}
                    valueLabelDisplay='auto'
                />
            </Box>
            <Box fontSize='0.8rem'>{description}</Box>
        </Box>
    );
}
