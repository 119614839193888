import { Box, Paper } from '@mui/material';

function InfoBox({ text, icon, sx, children, nowrapParent = false }) {
    return (
        <Paper
            sx={{
                p: 3,
                borderRadius: 3,
                borderColor: '#444',
                borderLeft: '8px solid #444',
                background: '#f1f1f1',
                display: 'flex',
                alignItems: 'center',
                flexWrap: { xs: 'wrap', lg: nowrapParent ? 'nowrap' : 'wrap' },
                gap: 2,
                ...sx
            }}
            variant='outlined'
        >
            <Box alignItems='center' display='inline-flex' gap={1}>
                {icon} {text}
            </Box>

            {children}
        </Paper>
    );
}

export default InfoBox;
