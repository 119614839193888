import {
    availableAuditEntriesAtom,
    currentAuditEntryIdAtom,
    reportDemoModeAtom
} from '@/global-store';
import { Autocomplete, Box, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * DomainSelect component
 * @param onChange - custom change handler, will disable reloading and does not save the domain to the global store
 * @param sx - sx style object
 * @returns {JSX.Element}
 * @constructor
 */
function DomainSelect({ onChange, width = '340px', sx = {} }) {
    const [currentAuditEntryId, setCurrentAuditEntryId] = useAtom(currentAuditEntryIdAtom);
    const [availableAuditEntries] = useAtom(availableAuditEntriesAtom);
    const [reportDemoMode] = useAtom(reportDemoModeAtom);

    const [tempCurrentAuditEntryId, setTempCurrentAuditEntryId] = useState(currentAuditEntryId);

    const location = useLocation();
    const path = location.pathname.endsWith('/')
        ? location.pathname.slice(0, -1)
        : location.pathname;

    const domains = useMemo(() => {
        if (!availableAuditEntries) return [];
        const _domains = [];

        const countMap = {};

        for (const entry of availableAuditEntries) {
            let label = entry.domain;

            if (label.startsWith('http://') || label.startsWith('https://')) {
                label = label.replace(/^(http|https):\/\//, '');
            }

            if (label.endsWith('/')) {
                label = label.slice(0, -1);
            }

            if (countMap[label]) {
                countMap[label]++;
                label = `${label} (${countMap[label]})`;
            } else {
                countMap[label] = 1;
            }

            _domains.push({
                ...entry,
                _label: label
            });
        }

        return _domains;
    }, [availableAuditEntries]);

    const entryId = onChange ? tempCurrentAuditEntryId : currentAuditEntryId;

    const currentEntry = useMemo(
        () => domains.find(domain => domain.id === entryId),
        [domains, entryId]
    );

    useEffect(() => {
        // if the onChange prop is set, we notify the parent component about the initial domain
        onChange?.(domains.find(domain => domain.id === tempCurrentAuditEntryId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const currentDomainName = currentEntry?._label;
    function domainSelected(id) {
        if (!id) return;
        if (id === currentAuditEntryId) return;
        if (onChange) {
            setTempCurrentAuditEntryId(id);
            return onChange(domains.find(domain => domain.id === id));
        }

        setCurrentAuditEntryId(id);
        let newPath;

        if (path.includes('/report/issues/issue')) {
            newPath = '/report/issues';
        } else if (path.includes('/report/pages/page')) {
            newPath = '/report/pages';
        } else if (path.includes('/report/analysis/lighthouse/page')) {
            newPath = '/report/analysis/lighthouse/';
        } else if (path.includes('/upload/pdf/')) {
            newPath = '/report/pdfs/upload';
        } else if (path.includes('/pdf/')) {
            newPath = '/report/pdfs/';
        }

        if (newPath) {
            return window.location.replace(newPath);
        }
    }

    if (!domains?.length || !currentDomainName) return <></>;

    if (reportDemoMode) return <b>Demo Domain</b>;

    return (
        <Grid
            container
            gap={1.25}
            alignItems='center'
            flexWrap='nowrap'
            sx={{
                width: { xs: '100%', sm: width },
                minWidth: 180,
                ...sx
            }}
        >
            {domains?.length < 2 ? (
                <Box
                    fontWeight={500}
                    padding='7.5px 12px'
                    border='1.75px solid rgba(18, 18, 18, 0.5)'
                    borderRadius={2}
                >
                    Domain: <b>{currentDomainName}</b>
                </Box>
            ) : (
                <Autocomplete
                    fullWidth
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                            label='Domain'
                            sx={{
                                backgroundColor: 'rgba(255,255,255,0.33)',
                                borderRadius: 2
                            }}
                        />
                    )}
                    sx={{
                        '& fieldset': {
                            borderRadius: 2,
                            border: '1.75px solid rgba(18, 18, 18, 0.5)'
                        }
                    }}
                    disableClearable
                    selectOnFocus
                    blurOnSelect
                    autoSelect
                    autoComplete
                    autoHighlight
                    openOnFocus
                    value={currentEntry}
                    onChange={(event, newValue) => domainSelected(newValue?.id)}
                    options={domains}
                    getOptionLabel={option => option?._label}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                />
            )}
        </Grid>
    );
}

export default DomainSelect;
